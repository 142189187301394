import { composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import type { ClassValue } from "clsx";
import { clsx } from "clsx";

/**
 * This tailwind-variants instance is used in some components to display a ring around them when they are focused
 */
export const focusRing = tv({
  base: `outline outline-offset-2 outline-primary-900`,
  variants: {
    /*
    variant: {
      primary: "!outline-secondary-main-100",
      secondary: "!outline-secondary-main-100",
      destructive: "!outline-error-main-500",
      error: "!outline-error-main-500",
      default: "!outline-primary-main-600",
    },
    */
    isFocusVisible: {
      false: "outline-0",
      true: "outline-2",
    },
  },
  /*
  defaultVariants: {
    variant: "default",
  },
  */
});

/**
 * react-aria-components adaptation to use tailwind with its own props (style props specially) by merging the two compiled classNames
 * @param className the className to merge
 * @param tw tailwind className
 */
export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string
): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}

/**
 * Function merging several classNames
 * @param inputs collection of classNames passed to a component
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
